import React from 'react';

const IconAchievement = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor">
    <title>Achievement</title>
    <path
      d="M14.1035 11.5356L13.9284 11.6779L13.9858 11.8962L15.6003 18.0342L10.2235 13.5735L10 13.3881L9.77653 13.5735L4.40657 18.0285L6.01374 12.0335L6.07157 11.8178L5.90031 11.6745L0.903937 7.49297L7.31679 7.13517L7.53981 7.12273L7.62246 6.91522L10 0.945886L12.3775 6.91522L12.4602 7.12273L12.6832 7.13517L19.0773 7.49192L14.1035 11.5356Z"
      fill="none"
    />
  </svg>
);

export default IconAchievement;
