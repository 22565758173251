import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          d="M35.7273 61H25.8693V31.9091H35.9261C38.8144 31.9091 41.2955 32.4915 43.3693 33.6562C45.4527 34.8116 47.053 36.4735 48.1705 38.642C49.2879 40.8106 49.8466 43.4053 49.8466 46.4261C49.8466 49.4564 49.2831 52.0606 48.1562 54.2386C47.0388 56.4167 45.4242 58.0881 43.3125 59.2528C41.2102 60.4176 38.6818 61 35.7273 61ZM31.1392 56.4403H35.4716C37.4981 56.4403 39.1884 56.071 40.5426 55.3324C41.8968 54.5843 42.9148 53.4716 43.5966 51.9943C44.2784 50.5076 44.6193 48.6515 44.6193 46.4261C44.6193 44.2008 44.2784 42.3542 43.5966 40.8864C42.9148 39.4091 41.9063 38.3059 40.571 37.5767C39.2453 36.8381 37.5975 36.4688 35.6278 36.4688H31.1392V56.4403Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
